.bottom-footer-social {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}

.bottom-footer-title {
    margin-bottom: 3px;
    padding: 3px;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.logo-metamask {
    width: 28px;
    height: 28px;
    margin: 2px;
    cursor: pointer;
}

.logo-metamask:hover {
    filter: blur(0.7px);
    width: 30px;
    height: 30px;
    transition: all 0.1s ease-in-out;
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

/* Red Arrow Up Style */
.triangle-up {
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 10px solid red;
}

ul {
    list-style-image: url('./assets/imgs/red-triangle-icon-10.png');
    list-style-position: inside;
    text-align: left;
}


/* Font Styles */
.mainHeader {
    font-weight: bolder;
    font-size: 1.5rem;
}

.regularText {
    font-size: 1.2rem;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 100px;
}

.headlines-text {
    font-size: 1.3rem;
    text-align: center;
    font-weight: 500;
}

a:link{
    color: #000
}
a:visited{
    color: #000
}

/* Components Alignment */
.bottom-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 65px;
    background-color: #FFF;
    border-top: 1px solid #CCC;
    padding-top: 10px;
    padding-bottom: 20px;
}

.bottom-footer a {
    color: #000;
    font-weight: bold;
    margin-right: 10px;
    padding: 3px;
}

.bottom-footer a:hover {
    color: #FFF;
    background-color: black;
    font-weight: bold;
    text-decoration: underline;
    border-radius: 3px;
    padding: 4px;
    transition: all 0.3s ease;
}

.headline-container{
    min-height: 100px;
}

.container-titles-video {
    margin-bottom: 70px;
    padding: 20px;
}


.list-button {
    width: 100% !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.list-icon {
    min-width: 20px !important;
    flex: 0 !important;
    margin-left: -10px;
}

.list-text {
    flex: 0 !important;
    min-width: max-content !important;
}

.list-tittle {
    font-size: 1.3rem!important;
    flex: 0 !important;
    min-width: max-content !important;
}

.container-video {
    width: 100%;
    height: 100%;
    display: flex !important;
    flex-direction: column !important;
    padding: 10px !important;
    justify-content: center !important;
    align-items: center !important;
    flex: 1 !important;
}

.video-container-title {
    font-size: 1.3rem !important;
    font-weight: 600 !important;
    text-align: center;
    margin-bottom: 10px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    flex: 1 !important;
}

@media screen and (max-width: 899px) {
    .home-screen-column {
        border-bottom: 1px solid #CCC;
        padding-bottom: 20px;
        margin-bottom: 30px !important;
    }

    .home-screen-column:first-child {
        border-top: 1px solid #CCC;
        padding-top: 30px;
    }

    .home-screen-column:last-child {
        padding-bottom: 0;
        border-bottom: none;
    }

    .regularText .info-text {
        width: 100%;
        text-align: center;
    }

    .title-line {
        border-bottom: 1px solid #CCC;
    }
}
